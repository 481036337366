import React from 'react';
import PropTypes from 'prop-types';

const Vimeo = ({ videoId, title }) => (
  <iframe
    src={`https://player.vimeo.com/video/${videoId}`}
    title={title}
    frameBorder="0"
    allow="autoplay; fullscreen"
    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
    allowFullScreen
  />
);

Vimeo.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Vimeo;
