import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const CenterCaption = styled.figcaption`
  width: 100%;
  text-align: center;
  padding-bottom: 48px;
  padding-top: 12px;
  font-style: italic;
`;

const EmbedParent = ({ embed, caption, ratio }) => (
  <figure>
    <div style={{ position: 'relative', paddingBottom: ratio, height: '0' }}>
      {embed}
    </div>
    <CenterCaption>
      {caption}
    </CenterCaption>
  </figure>
);

EmbedParent.propTypes = {
  embed: PropTypes.element.isRequired,
  caption: PropTypes.string.isRequired,
  ratio: PropTypes.string.isRequired,
};

export default EmbedParent;
