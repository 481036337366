import React from 'react';
import PropTypes from 'prop-types';

const Gfycat = ({ videoId, title }) => (
  <iframe
    src={`https://gfycat.com/ifr/${videoId}`}
    title={title}
    scrolling="no"
    width="100%"
    height="100%"
    style={{ position: 'absolute', top: '0', left: '0' }}
    allowFullScreen
    frameBorder="0"
  />
);

Gfycat.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Gfycat;
