import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Container, SEO, Duotone } from 'components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Img from 'gatsby-image';
import config from '../../config/website';
import { ProjectType } from '../components/ProjectCard/ProjectCard';
import { CaptionParent, Gfycat, Vimeo } from '../components/Embed';

const ArticleWrapper = styled.div`
  margin-top: calc(100vh - ${props => props.theme.toolbar.height} - ${props => props.theme.project.bannerDistance});
  background-color: white;
`;

const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - ${props => props.theme.toolbar.height} - ${props => props.theme.project.bannerDistance});
  z-index: -1;
  transform-origin: 0 0;
  transform: translateZ(-2px) scale(3);
  will-change: transform;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    transform: translateZ(-0.4px) scale(1.4);
  }

  ${props => props.theme.fallbackSelector} {
    transform: initial; 
  }
`;

const HeroImage = styled(Img)`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: ${props => props.shadowBlend};
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - ${props => props.theme.toolbar.height} - ${props => props.theme.project.bannerDistance});
  z-index: -2;
  transform-origin: 0 0;
  transform: translateZ(-3px) scale(4);
  will-change: transform;

  ${props => props.theme.fallbackSelector} {
    transform: initial; 
  }
`;

const Card = styled.div`
  position: absolute;
  padding-top: 48px;
  padding-left: 48px;
  width: 100%;
  height: 16em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 12px;
    padding-left: 12px;
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    bottom: 0;
    height: auto;
    padding-bottom: 16px;
  }
`;

const InfoText = styled(ProjectType)`
  font-size: 1.4em;
`;

const Header = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 700 !important;
  font-size: 4em;
  color: #fff;
  text-decoration: none;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 2.8em;
  }
`;

const StyledContainer = styled(Container)`
  @media (min-width: ${props => props.theme.breakpoints.s}) {
    transform: translateY(-60px);
  }
`;

const shortcodes = {
  Gfycat,
  CaptionParent,
  Vimeo,
};


const Project = ({ pageContext: { slug }, data: { mdx: postNode } }) => {
  const project = postNode.frontmatter;
  return (
    <React.Fragment>
      <Helmet title={`${project.title} | ${config.siteTitle}`} />
      <SEO postPath={slug} postNode={postNode} postSEO />
      <TitleWrapper>
        <Card>
          <Header>{project.title}</Header>
          <InfoText>{project.service}</InfoText>
          <InfoText>{project.client}</InfoText>
          <InfoText>{project.date}</InfoText>
        </Card>
      </TitleWrapper>
      <ImageWrapper>
        <Duotone
          shadowColor={project.shadowColor}
          shadowBlend={project.shadowBlend}
          highlightColor={project.highlightColor}
          highlightBlend={project.highlightBlend}
        >
          <HeroImage alt="" fluid={project.cover.childImageSharp.fluid} />
        </Duotone>
      </ImageWrapper>
      <ArticleWrapper>
        <StyledContainer type="text">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{postNode.body}</MDXRenderer>
          </MDXProvider>
        </StyledContainer>
      </ArticleWrapper>
    </React.Fragment>
  );
};

export default Project;

Project.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        date
        client
        service
        highlightColor
        shadowColor
        highlightBlend
        shadowBlend
        cover {
          childImageSharp {
            fluid(maxWidth: 750, grayscale: true, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
